<template>
  <div class="class_file"></div>
</template>
<script>
import { requestDataTGPMethod, isDev } from "@/utils/appManager";
export default {
  name: "ClassFile",
  components: {},
  data() {
    return {
      contentHtml: "",
    };
  },
  methods: {},
  mounted() {
    console.log("mounted");
    console.log(this.$route.params);
    const { id } = this.$route.params;
    requestDataTGPMethod(
      "requestData",
      "v3/courses/infoById",
      { id: id },
      "getTypeDataFromTgpNative",
      "1"
    );
  },
  created() {
    window["getTypeDataFromTgpNative"] = (data) => {
      console.log(data);
      this.contentHtml = data.data.section.content_html;
      setTimeout(() => {
        var fileDiv = document.getElementsByClassName("class_file")[0];
        fileDiv.innerHTML = this.contentHtml;
        var imgs = fileDiv.getElementsByTagName("img");
        var ps = fileDiv.getElementsByTagName("p");
        for (var i = 0; i < ps.length; i++) {
          var p = ps[i];
          p.style.width =(document.documentElement.clientWidth - 32)+"px";
        }
        console.log(imgs);
        for (var i = 0; i < imgs.length; i++) {
          var img = imgs[i];
          img.style.width = (document.documentElement.clientWidth - 32) +"px";
        }
      }, 300);
    };
  },
};
</script>
<style scoped  lang="scss" >
.class_file {
  padding-left: 16px;
  padding-right: 16px;
}
</style>